import dayjs from 'dayjs';
import notify from 'devextreme/ui/notify';

export const keyBy = (data, field = '_id') => {
  const output = {};

  if (Array.isArray(data)) {
    data.forEach((item) => {
      output[item[field]] = item;
    });
  } else if (typeof data === 'object') {
    output[data[field]] = data;
  }

  return output;
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const deleteCookie = async (key) => {
  document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getDataSource = (items, displayField = 'description') => {
  return {
    displayExpr: displayField,
    valueExpr: '_id',
    dataSource: {
      store: {
        type: 'array',
        data: Object.values(items ?? []),
        key: '_id',
      },
      paginate: true,
      pageSize: 10,
    },
  };
};

export const readFileAsDataURL = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const notification = (type, message, duration = 3) => {
  notify(
    {
      message,
      width: 'auto',
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 400, from: 1, to: 0 },
      },
      closeOnClick: true,
      position: { at: 'center top', offset: { x: 0, y: 100 } },
      type,
      displayTime: duration * 1000,
    },
    {
      position: 'top center',
      direction: 'down-stack',
    }
  );
};

export const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
