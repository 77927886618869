import { TreeView } from 'devextreme-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppMenuWrapper } from '../styled';
import { useAppDataSource, useProfile } from '../../store';
import { ROLE } from '../../constants/api';
import { MODEL } from '../../constants/models';

export const AppMenu = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const customObjectTypes = useAppDataSource(MODEL.CUSTOM_OBJECT_TYPE);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(menuItems(profile?.role, customObjectTypes));
  }, [profile?.role, customObjectTypes]);

  const handleClickItem = useCallback(
    (event) => {
      const { component, node, itemElement: item, itemData: data } = event;
      const isExpanded = node.expanded;
      const { path } = data;

      if (path) {
        navigate(path);
        const id = 'app-menu-selected';
        const currentSelected = document.getElementById(id);

        if (currentSelected) {
          currentSelected.id = undefined;
        }

        item.id = id;
      } else {
        if (isExpanded) {
          component.collapseItem(item);
        } else {
          component.expandItem(item);
        }
      }
    },
    [navigate]
  );

  if (!profile) {
    return <></>;
  }

  return (
    <AppMenuWrapper>
      <TreeView
        focusStateEnabled={false}
        activeStateEnabled={false}
        items={items}
        displayExpr="label"
        keyExpr={'key'}
        onItemClick={handleClickItem}
      />
    </AppMenuWrapper>
  );
};

const menuItems = (role, customObjects = []) => {
  const isSuperAdmin = role === ROLE.S_ADMIN;

  return [
    {
      label: 'Gastos',
      path: '/expenses',
      icon: 'fa-solid fa-receipt',
      key: 'expenses',
    },
    {
      label: 'Hojas de gastos',
      path: '/expenseSheets',
      icon: 'fa-solid fa-file-invoice-dollar',
      key: 'expenseSheets',
    },
    {
      label: 'Maestros',
      key: 'masters',
      icon: 'fa-solid fa-folder-tree',
      items: [
        {
          label: 'Clientes',
          path: '/businesses',
          key: 'businesses',
          icon: 'fa-solid fa-user-tie',
        },
        {
          label: 'Proveedores',
          path: '/providers',
          key: 'providers',
          icon: 'fa-solid fa-user-tie',
        },
        {
          label: 'Métodos de pago',
          path: '/paymentMethods',
          key: 'paymentMethods',
          icon: 'fa-solid fa-credit-card',
        },
        { label: 'Tags', path: '/tags', key: 'tags', icon: 'fa-solid fa-tags' },
        {
          label: 'Divisas',
          path: '/currencyCodes',
          key: 'currencyCodes',
          icon: 'fa-solid fa-dollar-sign',
        },
        {
          label: 'Supervisores',
          path: '/supervisors',
          key: 'supervisors',
          icon: 'group',
          visible: isSuperAdmin,
        },
        {
          label: 'Centros de trabajo',
          path: '/centers',
          key: 'centers',
          icon: 'fa-solid fa-building',
          visible: isSuperAdmin,
        },
        {
          label: 'Unidades de negocio',
          path: '/subcompanies',
          key: 'subcompanies',
          icon: 'fa-solid fa-briefcase',
          visible: isSuperAdmin,
        },
        {
          label: 'Grupos',
          path: '/groups',
          key: 'groups',
          icon: 'group',
          visible: isSuperAdmin,
        },
        {
          label: 'Plantillas de correo',
          path: '/emailTemplate',
          key: 'emailTemplate',
          icon: 'email',
          visible: isSuperAdmin,
        },
        {
          label: 'Campos personalizados',
          path: '/customField',
          key: 'customField',
          icon: 'fa-solid fa-diagram-project',
          visible: isSuperAdmin,
        },
        {
          label: 'Maestros personalizados',
          path: '/customObjectType',
          key: 'customObjectType',
          icon: 'fa-solid fa-file',
          visible: isSuperAdmin,
        },
      ],
    },
    {
      label: 'Viajes y vacaciones',
      icon: 'fa-solid fa-compass',
      key: 'travelAndVacation',
      items: [
        {
          label: 'Control de vacaciones',
          path: '/vacationControl',
          icon: 'fa-solid fa-mug-hot',
          key: 'companies',
        },
        {
          label: 'Control de viajes',
          path: '/travelControl',
          icon: 'fa-solid fa-plane',
          key: 'certificates',
        },
      ],
    },
    ...(customObjects.length === 0
      ? []
      : [
          {
            label: 'Maestros personalizados',
            key: 'customMasters',
            icon: 'fa-solid fa-file',
            items: customObjects.map((x) => {
              return {
                label: x.name,
                path: `/customObject/${x._id}`,
                icon: 'fa-solid fa-file',
                key: x._id,
              };
            }),
          },
        ]),
    {
      label: 'Control de accesos',
      path: '/signUps',
      icon: 'fa-solid fa-users',
      key: 'signUps',
    },
    {
      label: 'Usuarios',
      path: '/users',
      icon: 'fa-solid fa-users',
      key: 'users',
      visible: isSuperAdmin,
    },
    {
      label: 'Ajustes',
      path: '/settings',
      icon: 'fa-solid fa-gear',
      key: 'settings',
      visible: isSuperAdmin,
    },
  ];
};
